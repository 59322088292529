/* You can add global styles to this file, and also import other style files */

// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less
@font-family: Kanit, Tahoma;
@form-item-margin-bottom: 8px;
@input-disabled-color: #000000;
@disabled-color: #000000;
@select-multiple-item-disabled-color: #000000;
// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */
.iss-pagination-item {
  font-family: Kanit, Tahoma;
}

/*Custom Button ***************************************************************/
.ant-btn-warning {
  color: #fff !important;
  background-color: #faad14 !important;
  border-color: #d48806 !important;
}

.ant-btn-warning:hover, .ant-btn-warning:focus {
  color: #d48806 !important;
  background-color: #fffbe6 !important;
  border-color: #d48806 !important;
}

.ant-btn-info {
  color: #fff !important;
  background-color: #faad14 !important;
  border-color: #d48806 !important;
}

.ant-btn-info:hover, .ant-btn-info:focus {
  color: #d48806 !important;
  background-color: #fffbe6 !important;
  border-color: #d48806 !important;
}
/*End Custom Button ***********************************************************/

.ant-steps-item-title,
.ant-steps-item-description {
  font-size: 14px;
}

.ant-form-item-has-error .ant-btn {
  border-color: #ff4d4f;
  color: #ff4d4f;
}

.icon-in-collapse-panel {
    margin: 0.2em;
}

.ant-form-item-in-tb {
  margin-bottom: 0;
}

.dropdown-divider{
  margin: 10px 0;
}

.dropdown-footer-container{
  padding: 0 0 8px 10px;
}

.nz-select-loading-icon {
  margin-right: 8px;
}

.nz-select-acc-position-option {
  font-size: 12px;
  font-style: italic;
}

.nz-select-acc-com-option {
  font-size: 12px;
  font-weight: bold;
}
